<template>
  <div class="px-4 lg:px-8">
    <div class="container space-y-4 lg:space-y-6 pt-4 lg:pt-6">
      <LegacyPageProfileTabs
        v-if="!isArticle"
        :tabs="tabs"
        :ignore-sub-paths="['/article']"
      />
      <NewsCategory id="latest" showcase v-if="isLatest" />
      <NuxtPage keepalive v-if="!isLatest" />
    </div>
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
const isLatest = computed(() => !route.params.slug);
const isArticle = computed(() => route.path.includes("/article"));

const nuxtApp = useNuxtApp();
const locale = nuxtApp.$i18n.global.locale;
const mappedLocale = computed(() => reverseLocaleMap(locale.value));

const tabs = ref({
  current: "",
  default: "",
  rootPath: `/${mappedLocale.value}/news`,
  items: [
    { name: "Latest", slug: "" },
    { name: "Anime", slug: "category/anime" },
    { name: "Manga", slug: "category/manga" },
    { name: "Novels", slug: "category/novels" },
    { name: "Games", slug: "category/games" },
    { name: "NamiComi", slug: "category/namicomi" },
  ],
});

const seo = {
  title: `News`,
  description: `Discover the latest news on NamiComi.`,
  //slug: data.attributes.slug,
  coverUrl: "",
  href: `/en/news`,
};

nuxtApp.runWithContext(() =>
  useSeoMeta({
    title: seo.title,
    ogTitle: seo.title,
    twitterTitle: seo.title,
    description: seo.description,
    ogDescription: seo.description,
    twitterDescription: seo.description,
    ogUrl: seo.href,
    ogLocale: locale.value,
    ogImage: seo.coverUrl,
    ogImageSecureUrl: seo.coverUrl,
    twitterImage: seo.coverUrl,
    //ogImageHeight: "1200",
    //ogImageWidth: "630",
    ogImageUrl: seo.coverUrl,
    //ogImageType: "image/png",
    ogImageAlt: "NamiComi",
    ogLocaleAlternate: "en_GB",
  }),
);
</script>
